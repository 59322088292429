import { environmentCommon } from './environment.common';

export const environment = {
  ...environmentCommon,
  debugging: false,
  loadTranslationFromServer: true,
  host: 'https://testshop.weyland.at',
  api: {
    ...environmentCommon.api,
    host: 'https://testshop.weyland.at',
  },
  cms: {
    ...environmentCommon.cms,
    host: 'http://localhost:1338',
  },
  oauth2: {
    adminBasePath: 'https://testshop.weyland.at/hydra-admin',
  },
  sentry: { ...environmentCommon.sentry, active: true },
  name: 'TESTING',
};
